<template>
  <div class="container">
    <!-- 标题 -->
    <h1 class="title">班级大转盘</h1>

    <!-- 转盘组件 -->
    <LuckyWheel
        ref="myLucky"
        width="900px"
        height="900px"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        @start="startCallback"
        @end="endCallback"
    />

    <!-- 使用 PrizeModal -->
    <PrizeModal
        v-if="showModal"
        :visible="showModal"
        @update:visible="showModal = $event"
        title="恭喜你中奖!"
    >
      <p>你中奖了：{{ prize.fonts[0].text }}</p>
    </PrizeModal>
  </div>
</template>

<script>
import PrizeModal from './components/PrizeModal.vue'; // 导入 PrizeModal


export default {
  components: {
    PrizeModal
  },
  data () {
    return {
      showModal: false,  // 控制弹窗的显示与隐藏
      prize: null,       // 中奖信息
      blocks: [
        { padding: '10px', background: '#869cfa' }
      ],
      prizes: [
        { background: '#e9e8fe', fonts: [{ text: '郑语馨', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '张梓豪', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '修宇豪', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '熊昆', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '杨欣灵', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '童紫芸', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '许婉婷', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '高婉柔', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '王海翔', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '许诗语', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '徐昕艺', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '许辰逸', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '徐思绮', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '付孜昊', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '范芯蓝', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '周梓俊', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '修扬辉', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '黄嘉祥', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '范嘉怡', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '许正富', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '邹呈祥', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '连鑫', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '谢泽辉', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '黄芪', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '王永康', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '徐妤婷', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '郑金龙', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '郑佳轩', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '张喜', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '彭家轩', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '熊允泽', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '许迁浩', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '付欣媛', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '吴睿鑫', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '曾泽然', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '王文泽', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '郑嘉顺', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '郑谨妍', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '黎晶晶', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '高茜茜', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '张洪晨', lengthLimit: 1 }], range: 10 },
        { background: '#b8c5f2', fonts: [{ text: '吴谦谦', lengthLimit: 1 }], range: 10 },
        { background: '#e9e8fe', fonts: [{ text: '黄泽轩', lengthLimit: 1 }], range: 10 }
      ],
      buttons: [
        { radius: '40%', background: '#617df2' },
        { radius: '35%', background: '#afc8ff' },
        {
          radius: '30%', background: '#869cfa',
          pointer: true,
          fonts: [{ text: '开始', top: '-10px' }]
        }
      ]
    };
  },
  methods: {
    startCallback() {
      this.$refs.myLucky.play();
      setTimeout(() => {
        // 随机选择一个中奖索引
        const randomIndex = Math.floor(Math.random() * this.prizes.length);
        this.prize = this.prizes[randomIndex];
        // 停止抽奖并显示弹窗
        this.$refs.myLucky.stop(randomIndex);
      }, 3000);
    },
    endCallback(prize) {
      // 中奖后的回调
      this.prize = prize;
      this.showModal = true;
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4; /* Optional: background color */
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}
</style>
